<template>
    <IFrame :src="url" ref="viewerRef"/>
</template>
<script setup lang="ts">
    import IFrame from "o365.vue.components.Iframe.vue";
    import {computed,ref} from "vue";
    import Url from "o365.modules.url.ts";
    const viewerRef = ref();

    export interface Props {
        fileName: string,
        primKey: string,
        viewName: string,
        id: number
    };

    const props = defineProps<Props>();
    const url = computed(() => { return `/nt/foxit9?params=${Url.decodObject(props)}`})
    //const url = ref(`/foxit9?param=${Url.decodObject(props)}`);
    defineExpose({
        viewerRef
    })
</script>